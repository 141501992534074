import React, { useState , useEffect } from "react";
import axios from "axios"
import Design2 from "../../assets/images/Design2.png";
import Thumb1 from "../../assets/images/tumbnail1.PNG";
import Thumb2 from "../../assets/images/tumbnail2.PNG";
import Thumb3 from "../../assets/images/tumbnail3.PNG";
import Thumb4 from "../../assets/images/thumbnail4.PNG";
import "../Styles/Blog.css"

const Blog = () => {

  const [content, setContent] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      try
      {
        const response = await axios.get("http://localhost:5000/api/home/home-content");
        const data = response.data.reduce((acc, item) => {
          acc[item.element_id] = item.content;
          return acc;
        },{});
        setContent(data);
      }catch(error) {
        console.error("Error fetching content", error);
      }
    }
    fetchContent();
  }, [])

  return (
    <div>
      <div className="blog-container">
        <img src={Design2} alt="Design2" className="mt-[-30px] blog-design w-full"></img>
        <h1 className="px-16 text-[30px] blog-heading font-semibold mb-[30px] mt-[-140px]">{content["blog-heading-1"]}</h1>
        <div className="bg-gold pb-12 blogs-container">
          <div className="flex justify-evenly items-center blog-division">
            <div className="w-[250px] h-[380px] blog-container-1">
              <a href="https://youtu.be/SEulFvCttn4?si=PqfAdsah47hAYl2L" target="blank">
                <img src={Thumb2} className="w-[250px] h-[150px]" alt="thumbnail"></img>
                <p className="text-xs mt-2 mb-[5px]">{content["date-1"]}</p>
                <h5 className="text-[15px] font-medium mb-[5px]">
                {content["title-1"]}
                </h5>
                <h5 className="text-[15px]">
                  {content["desc-1"]}
                </h5>
              </a>
            </div>
            <div className="w-[250px] h-[380px] blog-container-2">
              <a href="https://youtu.be/sO_GQt_8J9s?si=EP9TRvdnuPsFAv3z" target="blank">
                <img src={Thumb1} className="w-[250px] h-[150px]" alt="thumbnail"></img>
                <p className="text-xs mt-2 mb-[5px]">{content["date-2"]}</p>
                <h5 className="text-[15px] font-medium mb-[5px]">
                {content["title-2"]}
                </h5>
                <h5 className="text-[15px]">
                {content["desc-2"]}
                </h5>
              </a>
            </div>
            <div className="w-[250px] h-[380px] blog-container-3">
              <a href="https://youtu.be/njEm-1lOTq8?si=mAMlBdtRXrci4Ba_" target="blank">
                <img src={Thumb3} className="w-[250px] h-[150px]" alt="thumbnail"></img>
                <p className="text-xs mt-2 mb-[5px]">{content["date-3"]}</p>
                <h5 className="text-[15px] font-medium mb-[5px]">
                {content["title-3"]}
                </h5>
                <h5 className="text-[15px]">
                {content["desc-3"]}
                </h5>
              </a>
            </div>
            <div className="w-[250px] h-[380px] blog-container-4">
              <a href="https://youtu.be/qPKvaIycoZE?si=moarIlR7FotlWznJ" target="blank">
                <img src={Thumb4} className="w-[250px] h-[150px]" alt="thumbnail"></img>{" "}
                <p className="text-xs mt-2 mb-[5px]">{content["date-4"]}</p>
                <h5 className="text-[15px] font-medium mb-[5px]">
                {content["title-4"]}
                </h5>
                <h5 className="text-[15px]">
                {content["desc-4"]}
                </h5>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
