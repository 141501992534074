import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import axios from "axios"
import logo from "../../assets/images/logo.png";
import fbicon from "../../assets/images/facebook (1).png"
import yticon from "../../assets/images/youtube.png"
import instaicon from "../../assets/images/insta.png"
import twicon from "../../assets/images/twitter.png"
import inicon from "../../assets/images/linkedin.png"
import "../Styles/Footer.css"

const Footer = () => {

  const [content, setContent] = useState({});

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/footer/footer-content");
        const data = response.data.reduce((acc, item) => {
          acc[item.element_id] = item.content;
          return acc;
        }, {});
        setContent(data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchContent();
  }, []);

  return (
    <div className="w-full bg-grey">
      <div className=" footer-container p-8 pl-12 ml-[-50px] flex items-center justify-evenly ">
        <div>
          <div >
            <Link to="/#home" className=" flex  space-x-2 items-center">
              <img src={logo} alt="logo" className="w-18 h-20"/>
              <div className="text-sm font-bold text-gray-800 pt-1">
                <span className="block">{content["footer-heading1"]}</span>
                <span className="block">{content["footer-heading2"]}</span>
              </div>
            </Link>
          </div><br/>
          <div>
            <p>{content["footer-para1"]} <br/>{content["footer-para12"]}</p>
            
          </div><br/>
          <div>
            <ul className= "flex justify-evenly items-center w-60 space-x-2 ">
              <li className="">
                <a href="#">
                    <img src={fbicon} alt="" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/f2hairtransplantandskincare?igsh=MXUwZGdzenA2ZjFucA==">
                    <img src={instaicon} alt="" />
                </a>
              </li>
              <li>
                <a href="https://youtube.com/@f2hairtransplantandskincare?si=7FeeoY_fJoxDofQX">
                    <img src={yticon} alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                    <img src={twicon} alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                    <img src={inicon} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-link-division">
          <ul className=" footer-list w-full h-16 ml[50px] mt-[-40px] font-medium flex justify-evenly items-center py-2 px-0 mr-[100px] ">
            <li>
              <Link to="/#home">{content["footer-link1"]}</Link>
            </li>
            <li>
              <Link to="/treatments/hair-care#haircare">{content["footer-link2"]}</Link>
            </li>
            <li>
              <Link to="/about#about">{content["footer-link3"]}</Link>
            </li>
            <li>
              <Link to="/contact#contact">{content["footer-link4"]}</Link>
            </li>
            <li>
              <Link to="/contact#appointment">{content["footer-link5"]}</Link>
            </li>
          </ul>
          <ul className="  footer-list footer-list1 w-full h-16 flex text-sm justify-evenly items-center py-2 px-0 ">
            <li>
              <Link to=" ">{content["footer-link6"]}</Link>
            </li>
            <li>
              <Link to=" ">{content["footer-link7"]}</Link>
            </li>
            <li>
              <Link to=" ">{content["footer-link8"]}</Link>
            </li>
          </ul>
        </div>
        <div className="ml-[50px] mt-[0] footer-address footer-link-division">
          <div>
            <p className="text-center">{content["footer-address1"]} <br/> {content["footer-address12"]}</p>
          </div>
          <div>
            <p className="text-center">{content["footer-address2"]}</p>
          </div>
          <div>
            <a href="tel:+91 72000 9924"><p className="text-center pt-4">{content["footer-address3"]}</p></a>
            <a href="tel:+91 93421 58391"><p className="text-center pt-2">{content["footer-address4"]}</p></a>
          </div>
        </div>
      </div>
      <div className="text-center text-m pb-4 text-zinc-800">
        <a href="https://emayamtech.com/" target="blank"><p>{content["footer-credit"]}</p></a>
      </div>
    </div>
  );
};

export default Footer;
