import React from 'react'
import "../Styles/GetInTouch.css"
import { Link } from "react-router-dom";

const GetInTouch = () => {
  return (
    <div>
      <div className='git-container bg-black w-full h-[250px]'>
      <h1 className='text-white text-center pt-[30px] text-[30px] cursor:pointer'>GET IN TOUCH WITH US</h1>
        <p className="text-white text-center pt-[30px] cursor:pointer">TO SOLVE ALL YOUR HAIR AND SKIN PROBLEMS WITH OUR ELITE TREATMENTS</p>
        <div className='getintouch-cont flex justify-center items-center  pt-[20px] pt-[40px]'>
        <Link to="/contact#contact"><p className=' getintouch-btn getintouch-btn1 text-[16px] text-center p-2 px-[60px] font-medium text-white rounded-3xl bg-gold'>CONTACT US</p></Link>
        <Link to="/contact#appointment"><p className='getintouch-btn getintouch-btn2 text-[16px] text-center p-2 px-4 font-medium text-white rounded-3xl bg-gold'>BOOK AN APPOINTMENT</p></Link>
        </div>
      </div>  
    </div>
  )
}

export default GetInTouch
