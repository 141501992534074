// src/App.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import Navbar from "./components/Website/Navbar";
import Footer from "./components/Website/Footer";
import "./App.css"; 
import GetInTouch from "./components/Website/GetInTouch";

function App() {
  return (
    <Router>
      <Navbar />

      {/* Render AppRoutes for route definitions */}
      <AppRoutes />
      <GetInTouch/>
      <Footer/>
    </Router>
  );
}

export default App;
