import React, { useState, useEffect } from "react";
import axios from "axios"
import {Link} from "react-router-dom"
import Haircare from "../assets/images/Services 1 (1).png";
import Skincare2 from "../assets/images/Services 22.png";
import Skincare from "../assets/images/Services 2.png";
import Haircare2 from "../assets/images/Services 12.png";
import Hair1 from "../assets/images/hairr1.png";
import Hair2 from "../assets/images/hairr2.png";
import Hair3 from "../assets/images/hairr3.png";
import Hair4 from "../assets/images/hairr4.png";
import Hair5 from "../assets/images/hairr5.png";
import Hair6 from "../assets/images/hairr6.png";


const HairCareTreatment = () => {
 

  const [activeTab, setActiveTab] = useState("HAIR CARE");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Determine which images to display based on the active tab
  const currentHairCareImage = activeTab === "HAIR CARE" ? Haircare : Haircare2;
  const currentSkinCareImage = activeTab === "SKIN CARE" ? Skincare2 : Skincare;

  const [content, setContent] = useState({});

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/hair/haircare");
        const data = response.data.reduce((acc, item) => {
          acc[item.element_id] = item.content;
          return acc;
        }, {});
        setContent(data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchContent();
  }, []);

  return (
    <div>
        <div className="flex justify-evenly items-center" id="haircare">
          {/* Hair Care Section */}
          <div
            className="relative w-1/2 h-48 sm:h-60 md:h-72 lg:h-80 cursor-pointer"
            onClick={() => handleTabClick("HAIR CARE")} 
          >
            <img
              src={currentHairCareImage}
              alt="Hair Care"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
              <h1 className="text-white text-lg sm:text-xl md:text-2xl font-bold py-1 px-2">
                {content["haircare-heading"]}
              </h1>
              <h3 className="text-gold text-sm sm:text-base md:text-lg font-semibold [text-shadow:_0_1px_0_rgb(0_0_0_/_100%)] px-2 mt-1">
              {content["haircare-subhead1"]}
              </h3>
              <p className="bg-white py-2 px-10 text-black rounded-3xl uppercase font-medium mt-6">{content["haircare-button"]}</p>
            </div>
          </div>

          {/* Skin Care Section */}
          
          <div
            className="relative w-1/2 h-48 sm:h-60 md:h-72 lg:h-80 cursor-pointer"
            onClick={() => handleTabClick("SKIN CARE")} id="skincare"
          >
          <Link to="/treatments/skin-care">
            <img
              src={currentSkinCareImage}
              alt="Skin Care"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
              <h1 className="text-gold text-lg sm:text-xl md:text-2xl font-bold py-1 px-2">
                {content["skincare-heading"]}
              </h1>
              <h4 className="text-white text-sm sm:text-base md:text-lg font-semibold px-2 mt-1">
              {content["skincare-subhead1"]}
              </h4>
              <p className="bg-gold py-2 px-10 text-black rounded-3xl uppercase font-medium mt-6">{content["skincare-button"]}</p>
            </div>
            </Link>
          </div>
          
        </div>

        {/* Content Area */}
        <div className="mt-8 text-center" >
              <h3 className="text-[30px] font-semibold">
              {content["treatments-heading"]}
              </h3>
              <p className="border-b-[3px] w-[250px] m-[auto] border-gold my-[18px]"></p>
              <p className="text-center px-[50px] pb-[50px]">
                {content["treatments-desc"]}
              </p>
              <div className="px-[50px] pb-[50px] flex flex-col justify-evenly space-y-14">
                <div id="hairloss" className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10">
                  <div className="w-full md:w-1/4">
                    <img className="hidden md:block" src={Hair1} alt="Treatment" />
                  </div>
                  <div className="text-left w-full md:w-3/4">
                    <h2 className="font-semibold mb-5">{content["hairloss-heading"]}</h2>
                    <p className="mb-5">
                    {content["hairloss-desc"]}
                    </p>
                    <h4 className="font-medium mb-5">
                    {content["hairloss-subhead1"]}
                    </h4>
                    <ol className="list-decimal pl-5">
                      <li>{content["hairloss-list1"]}</li>
                      <li>{content["hairloss-list2"]}</li>
                      <li>{content["hairloss-list3"]}</li>
                      <li>{content["hairloss-list4"]}</li>
                      <li>{content["hairloss-list5"]}</li>
                    </ol>
                  </div>
                </div>
                <div id="hairtransplant" className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10">
                  <div className="text-left w-full md:w-3/4">
                    <h2 className="font-semibold mb-5" >{content["hairtransplant-heading"]}</h2>
                    <p className="mb-5">
                      {content["hairtransplant-desc"]}
                    </p>
                    <h4 className="font-medium mb-5">
                    {content["hairtransplant-subhead1"]}
                    </h4>
                    <ol className="list-decimal pl-5">
                      <li>
                        <span className="font-medium">
                        {content["hairtransplant-list1"]}
                        </span>
                        {content["hairtransplant-list3"]}
                      </li>
                      <li>
                        <span className="font-medium">
                        {content["hairtransplant-list2"]}
                        </span>
                        {content["hairtransplant-list4"]}
                      </li>
                    </ol>
                  </div>
                  <div className="w-full md:w-1/4">
                    <img className="hidden md:block" src={Hair2} alt="Treatment" />
                  </div>
                </div>
                <div id="prp" className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10">
                  <div className="w-full md:w-1/4">
                    <img className="hidden md:block" src={Hair3} alt="Treatment" />
                  </div>
                  <div className="text-left w-full md:w-3/4">
                    <h2 className="font-semibold mb-5"></h2>
                    <p className="mb-5">
                    {content["prp-desc"]}
                    </p>
                    <h4 className="font-medium mb-5">
                    {content["prp-subhead1"]}
                    </h4>
                    <ol className="list-decimal pl-5 ">
                      <li>
                        <span className="font-medium">
                        {content["prp-list1"]}
                        </span>{" "}
                        {content["prp-list4"]}
                      </li>
                      <li>
                        <span className="font-medium">
                        {content["prp-list2"]}
                        </span>{" "}
                        {content["prp-list4"]}
                      </li>
                      <li>
                        <span className="font-medium">
                        {content["prp-list3"]}
                        </span>{" "}
                        {content["prp-list6"]}
                      </li>
                    </ol>
                  </div>
                </div>
                <div id="hairdensity" className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10">
                  <div className="text-left w-full md:w-3/4">
                    <h2 className="font-semibold mb-5">
                      {content["hairdensity-heading"]}
                    </h2>
                    <p className="mb-5">
                    {content["hairdensity-desc"]}
                    </p>
                    <h4 className="font-medium mb-5">
                    {content["hairdensity-subhead1"]}
                    </h4>
                    <ol className="list-decimal pl-5">
                      <li>
                        <span className="font-medium">
                          {content["hairdensity-list1"]}
                        </span>{" "}
                        {content["hairdensity-list5"]}
                      </li>
                      <li>
                        <span className="font-medium">{content["hairdensity-list2"]}</span> A
                        {content["hairdensity-list6"]}
                      </li>
                      <li>
                        <span className="font-medium">{content["hairdensity-list3"]}</span>{" "}
                        {content["hairdensity-list7"]}
                      </li>
                      <li>
                        <span className="font-medium">
                         {content["hairdensity-list4"]}
                        </span>{" "}
                        {content["hairdensity-list8"]}
                      </li>
                    </ol>
                  </div>
                  <div className="w-full md:w-1/4">
                    <img className="hidden md:block" src={Hair4} alt="Treatment" />
                  </div>
                </div>
                <div id="beard&mustache" className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10">
                  <div className="w-full md:w-1/4">
                    <img className="hidden md:block" src={Hair5} alt="Treatment" />
                  </div>
                  <div className="text-left w-full md:w-3/4">
                    <h2 className="font-semibold mb-5">
                      {content["beardmustache-heading"]}
                    </h2>
                    <p className="mb-5">
                    {content["beardmustache-desc"]}
                    </p>
                    <h4 className="font-medium mb-5">
                    {content["beardmustache-subhead1"]}
                    </h4>
                    <ol className="list-decimal pl-5">
                      <li>{content["beardmustache-list1"]}</li>
                      <li>{content["beardmustache-list2"]}</li>
                    </ol>
                    <p>
                      {content["scalpmigration-heading"]}
                    </p>
                  </div>
                </div>
                <div id="scalpmigration" className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10">
                  <div className="text-left w-full md:w-3/4">
                    <h2 className="font-semibold mb-5">{content["scalpmigration-heading"]}</h2>
                    <p className="mb-5">
                      {content["scalpmigration-desc"]}
                    </p>
                    <h4 className="font-medium mb-5">
                    {content["scalpmigration-subhead1"]}
                    </h4>
                    <ol className="list-decimal pl-5">
                      <li> {content["scalpmigration-list1"]}</li>
                      <li>{content["scalpmigration-list2"]}</li>
                    </ol>
                    <p>
                    {content["scalpmigration-finalpara"]}
                    </p>
                  </div>
                  <div className="w-full md:w-1/4">
                    <img className="hidden md:block" src={Hair6} alt="Treatment" />
                  </div>
                </div>
              </div>
              <p className="mb-5 p-[50px]">
              {content["final-desc"]}
              </p>
            </div>
      </div>
  );
};

export default HairCareTreatment;
