import React, { useState, useEffect } from "react";
import ContactBg from "../assets/images/Contact.png";
import Location from "../assets/images/location.png";
import Email from "../assets/images/mail.png";
import Mobile from "../assets/images/call.png";
import axios from "axios";

const Contact = () => {

  const [content, setContent] = useState({});

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/contact-app/contact");
        const data = response.data.reduce((acc, item) => {
          acc[item.element_id] = item.content;
          return acc;
        }, {});
        setContent(data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };
    fetchContent();
  }, []);

  const [selectedServices, setSelectedServices] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const servicesList = [
    "Hair Loss Treatment",
    "Hair Transplant",
    "PRP Treatment",
    "Hair Density Treatment",
    "Beard and Mustache Transplant",
    "Scalp Migration",
    "Skin Lightening",
    "Botox and Fillers",
    "Laser Hair Removal",
    "Acne Scar Removal",
    "Hydra Facial",
    "Tan Removal",
    "Chemical Peel",
    "Micro Needling",
    "Tattoo Removal",
    "Wart and Mole Removal",
    "Under Eye  Circle",
    "Laser Resurfacing",
    "Carbon Peel",
  ];

  const handleServiceChange = (service) => {
    setSelectedServices((prevSelected) =>
      prevSelected.includes(service)
        ? prevSelected.filter((s) => s !== service)
        : [...prevSelected, service]
    );
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const submitContactForm = async (e) => {
    e.preventDefault();
    const formData = {
      name: document.getElementById("name").value,
      phone: document.getElementById("phone").value,
      email: document.getElementById("email").value,
      subject: document.getElementById("subject").value,
      message: document.getElementById("message").value,
    };

    try {
      const response = await fetch(
        "http://localhost:5000/api/forms/contact_us",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        alert("Contact form submitted successfully!");
      } else {
        alert("Failed to submit contact form.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const submitAppointmentForm = async (e) => {
    e.preventDefault();
    const formData = {
      name: document.getElementById("name").value,
      phone: document.getElementById("phone").value,
      email: document.getElementById("email").value,
      location: document.getElementById("location").value,
      selected_services: selectedServices.join(", "), // Convert array to string
      date: document.getElementById("date").value,
      time: document.getElementById("time").value,
      message: document.getElementById("message").value,
    };

    try {
      const response = await fetch(
        "http://localhost:5000/api/forms/book_an_appointment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        alert("Appointment booked successfully!");
      } else {
        alert("Failed to book appointment.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${ContactBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh", // Ensures the div takes the full height of the viewport
      }}
    >
      <div className="bg-black bg-opacity-90 ">
        {" "}
        {/* Optional: Add a background to the form for better readability */}
        <div className="flex flex-col md:flex-row justify-evenly items-stretch  p-4 mb-10">
          <div className="flex flex-col md:flex-row justify-evenly items-center  p-4">
            <div className="flex flex-col items-center justify-evenly text-center w-full md:w-1/4 border-2 border-gold p-4 h-[200px] bg-gold text-black font-medium rounded">
              <img className="mb-4 w-10" src={Location} alt="icon1" id="addressimg"/>
              <p className="" id="address">
                {content["adress1"]}
                <br />
                {content["adress2"]}
              </p>
            </div>
            <div className="flex flex-col items-center justify-evenly text-center w-full md:w-1/4 border-2 border-gold p-4 h-[200px] bg-gold text-black  font-medium rounded">
              <img className="mb-4 w-16" src={Email} alt="icon2" />
              <p className="">{content["email"]}</p>
            </div>
            <div className="flex flex-col items-center justify-evenly text-center w-full md:w-1/4 border-2 border-gold p-4 h-[200px] bg-gold text-black  font-medium rounded">
              <img className="mb-4 w-10" src={Mobile} alt="icon3" />
              <p className="">{content["mobile1"]}</p>
              <p className="">{content["mobile2"]}</p>
            </div>
          </div>
        </div>
        <div className="mb-10">
          <h1 className="text-white font-semibold text-center">
          {content["getintouch"]}
          </h1>
        </div>
        {/* Book an Appointment form */}
        <div
          className="flex flex-col md:flex-row justify-evenly items-center"
          id="appointment"
        >
          <div className="w-full md:w-1/2 mb-20 mt-10">
            <form
              className="max-w-md mx-auto bg-transparent p-10 border-2 border-gold backdrop-blur-sm bg-white/10"
              onSubmit={submitAppointmentForm}
            >
              <div className="text-gold text-[25px] text-center mb-8">
                BOOK AN APPOINTMENT
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gold appearance-none text-white :border-gold :focus:border-gold focus:outline-none focus:ring-0 focus:border-gold peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="name"
                  className="peer-focus:font-medium absolute text-sm text-white text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-focus:text-gold peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Name
                </label>
              </div>
              <div className="flex justify-evenly items-center space-x-4">
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="tel"
                    // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    name="phone"
                    id="phone"
                    className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gold appearance-none text-white :border-gold :focus:border-gold focus:outline-none focus:ring-0 focus:border-gold peer"
                    placeholder=" "
                    required
                  />
                  <label
                    htmlFor="phone"
                    className="peer-focus:font-medium absolute text-sm text-white text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]  peer-focus:text-gold peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Phone Number
                  </label>
                </div>

                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gold appearance-none text-white :border-gold :focus:border-gold focus:outline-none focus:ring-0 focus:border-gold peer"
                    placeholder=" "
                    required
                  />
                  <label
                    htmlFor="email"
                    className="peer-focus:font-medium absolute text-sm text-white text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]  peer-focus:text-gold peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Email
                  </label>
                </div>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="location"
                  id="location"
                  className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gold appearance-none text-white :border-gold :focus:border-gold focus:outline-none focus:ring-0 focus:border-gold peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="location"
                  className="peer-focus:font-medium absolute text-sm text-white text-white duration-300 transform -translate-y-6 scale-75 top-3 -z -10 origin-[0]  peer-focus:text-gold peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Location
                </label>
              </div>

              <div className="relative w-full mb-5 group">
                <label
                  htmlFor="selected-services"
                  className="block mb-2 text-sm text-white"
                >
                  Selected Services
                </label>
                <div
                  id="selected-services"
                  onClick={toggleDropdown}
                  className="flex items-center justify-between py-2 px-0 w-full text-sm text-white border-b-2 border-gold :border-gold cursor-pointer"
                >
                  <span>
                    {selectedServices.length > 0
                      ? selectedServices.join(", ")
                      : "Select your services"}
                  </span>
                  <span className="text-gold">&#9662;</span> {/* Arrow icon */}
                </div>
                {isDropdownOpen && (
                  <div className="absolute mt-2 w-full bg-white border border-white-300 rounded shadow-md max-h-48 overflow-y-auto z-10">
                    {servicesList.map((service, index) => (
                      <label
                        key={index}
                        className="block px-4 py-2 text-sm text-white-700 text-white-700 cursor-pointer hover:bg-white-100"
                      >
                        <input
                          type="checkbox"
                          checked={selectedServices.includes(service)}
                          onChange={() => handleServiceChange(service)}
                          className="mr-2"
                        />
                        {service}
                      </label>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex justify-evenly items-center space-x-4">
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="date"
                    name="date"
                    id="date"
                    className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gold appearance-none text-white :border-gold :focus:border-gold focus:outline-none focus:ring-0 focus:border-gold peer"
                    style={{ colorScheme: "dark" }} // Ensures the calendar icon appears white
                    required
                  />
                  <label
                    htmlFor="date"
                    className="peer-focus:font-medium absolute text-sm text-white text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-gold peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Date
                  </label>
                </div>

                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="time"
                    name="time"
                    id="time"
                    className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gold appearance-none text-white :border-gold :focus:border-gold focus:outline-none focus:ring-0 focus:border-gold peer"
                    style={{ colorScheme: "dark" }} // Ensures the clock icon appears white
                    required
                  />
                  <label
                    htmlFor="time"
                    className="peer-focus:font-medium absolute text-sm text-white text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-gold peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Time
                  </label>
                </div>
              </div>

              <div className="relative z-0 w-full mb-5 group">
                <textarea
                  name="message"
                  id="message"
                  rows="4"
                  className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gold appearance-none text-white :border-gold :focus:border-gold focus:outline-none focus:ring-0 focus:border-gold peer"
                  placeholder=" "
                  required
                ></textarea>
                <label
                  htmlFor="message"
                  className="peer-focus:font-medium absolute text-sm text-white text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]  peer-focus:text-gold peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Message
                </label>
              </div>

              <button
                type="submit"
                className=" text-black bg-gold hover:bg-gold focus:ring-4 focus:outline-none focus:ring-gold font-semibold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center :bg-gold :hover:bg-gold :focus:ring-gold"
              >
                SUBMIT
              </button>
            </form>
          </div>

          {/* Contact Form */}
          <div className="w-full md:w-1/2 mb-20 mt-10" id="contact">
            <form
              className="max-w-md mx-auto bg-transparent p-10 border-2 border-gold backdrop-blur-sm bg-white/10"
              onSubmit={submitContactForm}
            >
              <div className="text-gold text-[25px] text-center mb-8">
                SEND A MESSAGE
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gold appearance-none text-white :border-gold :focus:border-gold focus:outline-none focus:ring-0 focus:border-gold peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="name"
                  className="peer-focus:font-medium absolute text-sm text-white text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-focus:text-gold peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Name
                </label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="tel"
                  // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  name="phone"
                  id="phone"
                  className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gold appearance-none text-white :border-gold :focus:border-gold focus:outline-none focus:ring-0 focus:border-gold peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="phone"
                  className="peer-focus:font-medium absolute text-sm text-white text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]  peer-focus:text-gold peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Phone Number
                </label>
              </div>

              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gold appearance-none text-white :border-gold :focus:border-gold focus:outline-none focus:ring-0 focus:border-gold peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="email"
                  className="peer-focus:font-medium absolute text-sm text-white text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]  peer-focus:text-gold peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Email
                </label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gold appearance-none text-white :border-gold :focus:border-gold focus:outline-none focus:ring-0 focus:border-gold peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="subject"
                  className="peer-focus:font-medium absolute text-sm text-white text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-focus:text-gold peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Subject
                </label>
              </div>

              <div className="relative z-0 w-full mb-5 group">
                <textarea
                  name="message"
                  id="message"
                  rows="4"
                  className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gold appearance-none text-white :border-gold :focus:border-gold focus:outline-none focus:ring-0 focus:border-gold peer"
                  placeholder=" "
                  required
                ></textarea>
                <label
                  htmlFor="message"
                  className="peer-focus:font-medium absolute text-sm text-white text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]  peer-focus:text-gold peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Message
                </label>
              </div>

              <button
                type="submit"
                className="mb-[88px] text-black bg-gold hover:bg-gold focus:ring-4 focus:outline-none focus:ring-gold font-semibold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center :bg-gold :hover:bg-gold :focus:ring-gold"
              >
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
