// src/routes/AppRoutes.js
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Import components
import Home from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import HairCareTreatment from "../pages/HairCareTreatment";
import SkinCareTreatment from "../pages/SkinCareTreatment";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/treatments/hair-care" element={<HairCareTreatment />} />
      <Route path="/treatments/skin-care" element={<SkinCareTreatment />} />
      <Route path="/contact/appointment" element={<Contact />} />
      {/* Optional: Redirect /treatments to /treatments/hair-care */}      
      <Route
        path="/treatments"
        element={<Navigate to="/treatments/hair-care" />}
      />
      {/* Optional: Redirect /treatments to /treatments/hair-care */}
      <Route path="/appointment" element={<Navigate to="/contact" />} />
    </Routes>
  );
};

export default AppRoutes;
