import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import "swiper/css"; // Import Swiper styles
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../Styles/Carousal.css";

// Import required Swiper modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";

import carousal1 from "../../assets/images/carousal 1 -1.png";
import carousal2 from "../../assets/images/carousal 2 -2.png";
import carousal3 from "../../assets/images/carousal 3 -1.png";
import carousal4 from "../../assets/images/carousal 4 -2.png";

const slides = [
  {
    image: carousal1,
    title: "No Hair Loss is Permanent with Our Expertise",
    description:
      "We specialize in offering the most advanced solutions for all types of hair loss and hairline concerns.",
    points: [
      "1. PRP Hair Therapy",
      "2. Hair Transplant",
      "3. Medication",
      "4. Laser Therapy",
      "5. Nutritional Support",
    ],
  },
  {
    image: carousal2,
    title: "Revitalize Your Hair Naturally with PRP Therapy",
    description:
      "We offer Platelet-Rich Plasma (PRP) treatment, a revolutionary non-surgical solution for hair thinning and hair loss.",
    points: [
      "1. Stimulate hair growth",
      "2. Rejuvenate hair follicles",
      "3. Improve overall hair density",
    ],
  },
  {
    image: carousal3,
    title: "Reveal Radiant Skin with Our Skin Lightening Treatment",
    description:
      "We understand the desire for a brighter, more even complexion. Our Skin Lightening treatment offers safe, effective solutions.",
    points: ["1. Pigmentation", "2. Dark Spots", "3. Uneven Skin Tone"],
  },
  {
    image: carousal4,
    title: "Say Goodbye to Unwanted Hair with Laser Hair Removal",
    description:
      "We offer Laser Hair Removal, a safe and effective solution for long-term hair reduction.",
    points: [
      "1. Smooth Skin",
      "2. Hair Free Skin",
      "3. Removes dead skin cells",
      "4. Flawless Skin",
    ],
  },
];

const Carousel = () => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(
          "http://localhost:5000/api/home/home-content"
        );
        const data = response.data.reduce((acc, item) => {
          acc[item.element_id] = item.content;
          return acc;
        }, {});
        setContent(data);
      } catch (error) {
        console.error("Error fetching content", error);
      }
    };
    fetchContent();
  }, []);

  return (
    <div id="home">
      <Swiper
        modules={[Pagination, Navigation, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop={true}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <img
              src={slide.image}
              className="w-full h-[500px] object-cover"
              alt={`Slide ${index + 1}`}
            />
            {/* Content Overlay */}
            <div className="car-content absolute top-[50px] left-[50%] transform -translate-x-1/2 text-center">
              <h3 className="text-title font-bold text-gold mb-[20px] uppercase">
                {content[`slide-title-${index + 1}`]}
              </h3>
              <p className="text-description mb-[30px] text-white">
                {content[`slide-desc-${index + 1}`]}
              </p>
              <div className="text-points mb-[30px] text-gold font-semibold">
                {slide.points.map((point, idx) => (
                  <p key={`point-${index + 1}-${idx + 1}`}>
                    {content[`slide-point-${index + 1}-${idx + 1}`]}
                  </p>
                ))}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
