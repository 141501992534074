import React, {useEffect, useState} from "react";
import axios from "axios";
import Design from "../../assets/images/Design.png";
import "../Styles/Values.css"

const Values = () => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      try
      {
        const response = await axios.get("http://localhost:5000/api/home/home-content");
        const data = response.data.reduce((acc, item) => {
          acc[item.element_id] = item.content;
          return acc;
        },{});
        setContent(data);
      }catch(error) {
        console.error("Error fetching content", error);
      }
    }
    fetchContent();
  }, [])

  return (
    <div >
      <div className="w-full h-[490px] bg-gold text-center py-6 px-20 rounded-t-full overflow-hidden values-container">
        <h1 className="text-[30px] font-semibold" id="ourvalues">{content["ourvalues"]}</h1>
        <h2 className="text-[20px] font-medium pt-4" id="guaranteedresults">{content["guaranteedresults"]}</h2>
        <h4 className="pt-4" id="treatyoucare">
        {content["treatyoucare"]}
        </h4>
        <p className="pt-4 pr-10 pl-10" id="corevalues">
        {content["corevalues"]}
        </p>
        <p className="pt-4" id="commitment">
        {content["commitment"]}
        </p>
        <div className="pt-4 flex justify-center items-center space-x-32">
          <ul className="list-disc text-left space-y-4" >
            <li id="hairrestoration">{content["hairrestoration"]}</li>
            <li id="skinrejuvenation">{content["skinrejuvenation"]}</li>
            <li id="prptreatment">{content["prptreatment"]}</li>
            <li id="scalpmigration">{content["scalpmigration"]}</li>
          </ul>
          <ul className="list-disc text-left space-y-4">
            <li id="pigmentation">{content["pigmentation"]}</li>
            <li id="hairremoval">{content["hairremoval"]}</li>
            <li id="tattooremoval">{content["tattooremoval"]}</li>
            <li id="antiaging">{content["antiaging"]}</li>
          </ul>
        </div>
        <div className="flex justify-between items-center mt-[-160px] mr-[-200px] ml-[-200px]">
          <img id="design" src={Design} alt="design"/>
          <img id="design2" src={Design} alt="design" className="scale-x-[-1] mt-[-120px]"/>
        </div>
      </div>
    </div>
  );
};

export default Values;
