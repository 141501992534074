import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom"
import axios from "axios";
import Haircare from "../assets/images/Services 1 (1).png";
import Skincare2 from "../assets/images/Services 22.png";
import Skincare from "../assets/images/Services 2.png";
import Haircare2 from "../assets/images/Services 12.png";
import Skin1 from "../assets/images/skin1.png";
import Skin2 from "../assets/images/skin2.png";
import Skin3 from "../assets/images/skin3.png";
import Skin4 from "../assets/images/skin4.png";
import Skin5 from "../assets/images/skin5.png";
import Skin6 from "../assets/images/skin6 (2).png";
import Skin7 from "../assets/images/Skin7 (2).png";
import Skin8 from "../assets/images/Skin8 (2).png";
import Skin9 from "../assets/images/Skin9 (2).png";
import Skin10 from "../assets/images/Skin10.png";
import Skin11 from "../assets/images/Skin11 (2).png";
import Skin12 from "../assets/images/Skin12.png";
import Skin13 from "../assets/images/Skin13.png";

const SkinCareTreatment = () => {


  const [activeTab, setActiveTab] = useState("SKIN CARE");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Determine which images to display based on the active tab
  const currentHairCareImage = activeTab === "HAIR CARE" ? Haircare : Haircare2;
  const currentSkinCareImage = activeTab === "SKIN CARE" ? Skincare2 : Skincare;

  const [content, setContent] = useState({});

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/skin/skincare");
        const data = response.data.reduce((acc, item) => {
          acc[item.element_id] = item.content;
          return acc;
        }, {});
        setContent(data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchContent();
  }, []);

  return (
    <div>
      
      <div className="flex justify-evenly items-center" id="skincare">
        {/* Hair Care Section */}
        <div
          className="relative w-1/2 h-48 sm:h-60 md:h-72 lg:h-80 cursor-pointer"
          onClick={() => handleTabClick("HAIR CARE")}
        >
        <Link to="/treatments/hair-care">
          <img
          id="haircareimg"
            src={currentHairCareImage}
            alt="Hair Care"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
            <h1 className="text-gold text-lg sm:text-xl md:text-2xl font-bold py-1 px-2" id="haircarebg">
              {content["haircarebg"]}
            </h1>
            <h3 className="text-white text-sm sm:text-base md:text-lg font-semibold px-2 mt-1" id="haircaerbgpara">
              {content["haircaerbgpara"]}
            </h3>
            <p className="bg-gold py-2 px-10 text-black rounded-3xl uppercase font-medium mt-6" id="haircareviewmore">{content["haircareviewmore"]}</p>
          </div>
          </Link>
        </div>

        {/* Skin Care Section */}
        <div
          className="relative w-1/2 h-48 sm:h-60 md:h-72 lg:h-80 cursor-pointer"
          onClick={() => handleTabClick("SKIN CARE")}
        >
          <img
          id="skincareimg"
            src={currentSkinCareImage}
            alt="Skin Care"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
            <h1 className="text-white text-lg sm:text-xl md:text-2xl font-bold py-1 px-2" id="skincarebg">
            {content["skincarebg"]}
            </h1>
            <h4 className="text-gold text-sm [text-shadow:_0_1px_0_rgb(0_0_0_/_100%)] sm:text-base md:text-lg font-semibold px-2 mt-1" id="skincarebgpara">
            {content["skincarebgpara"]}
            </h4>
            <p className="bg-white py-2 px-10 text-black rounded-3xl uppercase font-medium mt-6" id="skincareviewmore">{content["skincareviewmore"]}</p>
          </div>
        </div>
      </div>
      

      <div className="mt-8 text-center">
        <h3 className="text-[30px] font-semibold text-center" id="skincare-heading">{content["skincare-heading"]}</h3>
        <p className="border-b-[3px] w-[250px] m-[auto] border-gold my-[18px]"></p>
        <p className="text-center px-[50px] pb-[50px]" id="skincarepara">
        {content["skincarepara"]}
        </p>
        <div className="px-[50px] pb-[50px] flex flex-col justify-between space-y-14">

          <div className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10" id="skin-lightening">
            <div className="w-full md:w-1/4">
              {/* Remove image on small screens */}
              <img className="hidden md:block" src={Skin1} alt="Treatment" id="skinlightimg" />
            </div>
            <div className="text-left w-full md:w-3/4">
              <h2 className="font-semibold mb-5" id="skinlight-heading">{content["skinlight-heading"]}</h2>
              <p className="mb-5" id="skinlight-paragraph">
              {content["skinlight-paragraph"]}
              </p>
              <h4 className="font-medium mb-5" id="skinlight-subhead">
              {content["skinlight-subhead"]}
              </h4>
              <ol className="list-decimal pl-5">
                <li id="skinlight-list1">{content["skinlight-list1"]}</li>
                <li id="skinlight-list2">{content["skinlight-list2"]}</li>
                <li id="skinlight-list3">{content["skinlight-list3"]}</li>
                <li id="skinlight-list4">{content["skinlight-list4"]}</li>
                <li id="skinlight-list5">{content["skinlight-list5"]}</li>
              </ol>
              <p id="skinlight-endpara">
              {content["skinlight-endpara"]}
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10" id="botox">
            <div className="text-left w-full md:w-3/4">
              <h2 className="font-semibold mb-5" id="botox-heading">{content["botox-heading"]}</h2>
              <p className="mb-5" id="botox-para">
              {content[""]}
              </p>
              <h4 className="font-medium mb-5" id="botox-subhead1">{content["botox-subhead1"]}</h4>
              <ol className="list-decimal pl-5">
                <li id="botox-list1">{content["botox-list1"]}</li>
                <li id="botox-list2">{content["botox-list2"]}</li>
                <li id="botox-list3">
                {content["botox-list3"]}
                </li>
              </ol>
              <h4 className="font-medium mb-5" id="botox-subhead2">{content["botox-subhead2"]}</h4>
              <ol className="list-decimal pl-5">
                <li id="botox-list4">
                {content["botox-list4"]}
                </li>
                <li id="botox-list5">
                {content["botox-list5"]}
                </li>
                <li id="botox-list6">{content["botox-list6"]}</li>
                <li id="botox-list7">{content["botox-list7"]}</li>
              </ol>
            </div>
            <div className="w-full md:w-1/4">
              <img className="hidden md:block" src={Skin6} alt="Treatment" id="botoximg" />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10" id="laser">
            <div className="text-left w-full md:w-1/4 ">
              <img className="hidden md:block" src={Skin4} alt="Treatment" id="laserimg"/>
            </div>
            <div className="text-left w-full md:w-3/4">
              <h2 className="font-semibold mb-5" id="laser-heading">{content["laser-heading"]}</h2>
              <p className="mb-5" id="laser-para">
              {content["laser-para"]}
              </p>
              <ol className="list-decimal pl-5 ">
                <li id="laser-list1">{content["laser-list1"]}</li>
                <li id="laser-list2">{content["laser-list2"]}</li>
                <li id="laser-list3">{content["laser-list3"]}</li>
                <li id="laser-list4">{content["laser-list4"]}</li>
                <li id="laser-list5">{content["laser-list5"]}</li>
                <li id="laser-list6">{content["laser-list6"]}</li>
                <li id="laser-list7">{content["laser-list7"]}</li>
              </ol>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10 " id="acnescarremoval">
            <div className="text-left w-full md:w-3/4">
              <h2 className="font-semibold mb-5" id="acne-heading">{content["acne-heading"]}</h2>
              <p className="mb-5" id="acne-para">
              {content["acne-para"]}  
              </p>
              <h4 className="font-medium mb-5" id="acne-subhead">
              {content["acne-subhead"]}
              </h4>
              <ol className="list-decimal pl-5">
                <li id="acne-list1">{content["acne-list1"]}</li>
                <li id="acne-list2">{content["acne-list2"]}</li>
                <li id="acne-list3">{content["acne-list3"]}</li>
                <li id="acne-list4">{content["acne-list4"]}</li>
              </ol>
            </div>
            <div className="w-full md:w-1/4">
              <img className="hidden md:block" src={Skin2} alt="Treatment" id="acneimg"/>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10" id="hydrafacial">
            <div className="text-left w-full md:w-1/4">
              <img className="hidden md:block" src={Skin5} alt="Treatment" id="hydraimg"/>
            </div>
            <div className="text-left w-full md:w-3/4">
              <h2 className="font-semibold mb-5" id="hydra-heading">{content["hydra-heading"]}</h2>
              <p className="mb-5" id="hydra-para">
              {content["hydra-para"]}
              </p>
              <h4 className="font-medium mb-5" id="hydra-subhead">{content["hydra-subhead"]}</h4>
              <ol className="list-decimal pl-5">
                <li id="hydra-list1">{content["hydra-list1"]}</li>
                <li id="hydra-list2">{content["hydra-list2"]}</li>
                <li id="hydra-list3">{content["hydra-list3"]}</li>
                <li id="hydra-list4">{content["hydra-list4"]}</li>
              </ol>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10 " id="tattooremoval">
            <div className="text-left w-full md:w-3/4">
              <h2 className="font-semibold mb-5" id="tattoo-heading">{content["tattoo-heading"]}</h2>
              <p className="mb-5" id="tattoo-para">
              {content["tattoo-para"]}
              </p>
              <h4 className="font-medium mb-5" id="tattoo-subhead">{content["tattoo-subhead"]}</h4>
              <p id="tattoo-subhead-para">
              {content["tattoo-subhead-para"]}
              </p>
            </div>
            <div className="w-full md:w-1/4">
              <img className="hidden md:block" src={Skin3} alt="Treatment" id="tattoo-img"/>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10" id="microneedling">
            <div className="text-left w-full md:w-1/4">
              <img className="hidden md:block" src={Skin7} alt="Treatment" id="micro-img"/>
            </div>
            <div className="text-left w-full md:w-3/4">
              <h2 className="font-semibold mb-5" id="micro-heading">{content["micro-heading"]}</h2>
              <p className="mb-5" id="micro-para">
              {content["micro-para"]}
              </p>
              <h4 className="font-medium mb-5" id="micro-subhead">{content["micro-subhead"]}</h4>
              <ol className="list-decimal pl-5">
                <li id="micro-list1">{content["micro-list1"]}</li>
                <li id="micro-list2">{content["micro-list2"]}</li>
                <li id="micro-list3">{content["micro-list3"]}</li>
                <li id="micro-list4">{content["micro-list4"]}</li>
                <li id="micro-list5">{content["micro-list5"]}</li>
              </ol>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10" id="tanremoval">
            <div className="text-left w-full md:w-3/4">
              <h2 className="font-semibold mb-5" id="tan-heading">{content["tan-heading"]}</h2>
              <p className="mb-5" id="tan-para">
              {content["tan-para"]}
              </p>
              <h4 className="font-medium mb-5" id="tan-subhead">
              {content["tan-subhead"]}
              </h4>
              <ol className="list-decimal pl-5">
                <li id="tan-list1">{content["tan-list1"]}</li>
                <li id="tan-list2">{content["tan-list2"]}</li>
                <li id="tan-list3">{content["tan-list3"]}</li>
              </ol>
              <p id="tan-subhead-para">
              {content[""]}
              </p>
            </div>
            <div className="w-full md:w-1/4">
              <img className="hidden md:block" src={Skin8} alt="Treatment" id="tanimg"/>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10" id="chemicalpeel">
            <div className="text-left w-full md:w-1/4">
              <img className="hidden md:block" src={Skin9} alt="Treatment" id="chemicalimg"/>
            </div>
            <div className="text-left w-full md:w-3/4">
              <h2 className="font-semibold mb-5" id="chemical-heading">{content["chemical-heading"]}</h2>
              <p className="mb-5" id="chemical-para">
              {content["chemical-para"]}
              </p>
              <h4 className="font-medium mb-5" id="chemical-subhead">
              {content["chemical-subhead"]}
              </h4>
              <ol className="list-decimal pl-5 ">
                <li id="chemical-list1">
                {content["chemical-list1"]}
                </li>
                <li id="chemical-list2">
                {content["chemical-list2"]}
                </li>
                <li id="chemical-list3">
                {content["chemical-list3"]}
                </li>
              </ol>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10" id="wart&mole">
            <div className="text-left w-full md:w-3/4">
              <h2 className="font-semibold mb-5" id="wart-heading">{content["wart-heading"]}</h2>
              <p className="mb-5" id="wart-para">
              {content["wart-para"]}
              </p>
              <h4 className="font-medium mb-5" id="wart-subhead">
              {content["wart-subhead"]}
              </h4>
              <ol className="list-decimal pl-5">
                <li id="wart-list1">{content["wart-list1"]}</li>
                <li id="wart-list2">{content["wart-list2"]}</li>
                <li id="wart-list3">{content["wart-list3"]}</li>
              </ol>
            </div>
            <div className="w-full md:w-1/4">
              <img className="hidden md:block" src={Skin10} alt="Treatment" id="wartimg"/>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10" id="undereyecircle">
            <div className="w-full md:w-1/4">
              <img className="hidden md:block" src={Skin11} alt="Treatment" id="underimg"/>
            </div>
            <div className="text-left w-full md:w-3/4">
              <h2 className="font-semibold mb-5" id="under-heading">{content["under-heading"]}</h2>
              <p className="mb-5" id="under-para">
              {content["under-para"]}
              </p>
              <h4 className="font-medium mb-5" id="under-subhead">
              {content["under-subhead"]}
              </h4>
              <ol className="list-decimal pl-5">
                <li id="under-list1">
                {content["under-list1"]}
                </li>
                <li id="under-list2">
                {content["under-list2"]}
                </li>
                <li id="under-list3">
                {content["under-list3"]}
                </li>
                <li id="under-list4">
                {content["under-list4"]}
                </li>
                <li id="under-list5">
                {content["under-list5"]}
                </li>
              </ol>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10" id="laserresurfacing">
            <div className="text-left w-full md:w-3/4">
              <h2 className="font-semibold mb-5" id="laserresurf-heading">{content["laserresurf-heading"]}</h2>
              <p className="mb-5" id="laserresurf-para">
              {content["laserresurf-para"]}
              </p>
              <h4 className="font-medium mb-5" id="laserresurf-subhead">
              {content["laserresurf-subhead"]}
              </h4>
              <ol className="list-decimal pl-5">
                <li id="laserresurf-list1">
                {content["laserresurf-list1"]}
                </li>
                <li id="laserresurf-list2">
                {content["laserresurf-list2"]}
                </li>
                <li id="laserresurf-list3">
                {content["laserresurf-list3"]}
                </li>
              </ol>
            </div>
            <div className="w-full md:w-1/4">
              <img className="hidden md:block" src={Skin12} alt="Treatment" id="laserresurfimg"/>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center w-full lg:space-x-10 md:space-x-10" id="carbonpeel">
            <div className="w-full md:w-1/4">
              <img className="hidden md:block" src={Skin13} alt="Treatment" id="carbonimg"/>
            </div>
            <div className="text-left w-full md:w-3/4">
              <h2 className="font-semibold mb-5" id="carbon-heading">{content["carbon-heading"]}</h2>
              <p className="mb-5" id="carbon-para">
              {content["carbon-para"]}
              </p>
              <h4 className="font-medium mb-5" id="carbon-subhead">{content["carbon-subhead"]}</h4>
              <ol className="list-decimal pl-5">
                <li id="carbon-list1">
                {content["carbon-list1"]}
                </li>
                <li id="carbon-list2">
                {content["carbon-list2"]}
                </li>
                <li id="carbon-list3">
                {content["carbon-list3"]}
                </li>
                <li id="carbon-list4">
                {content["carbon-list4"]}
                </li>
                <li id="carbon-list5">
                {content["carbon-list5"]}
                </li>
              </ol>
            </div>
          </div>
        </div>
        <p className="mb-5 p-[50px] pt-[20px]" id="final-skin">
        {content["final-skin"]}
        </p>
      </div>
    </div>
  );
};

export default SkinCareTreatment;
