import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Hairgrowth from "../../assets/images/Hair growth.png";
import SkinRejuve from "../../assets/images/Skin rejuvenation.png";
import AcneScar from "../../assets/images/Acne treatment.png";
import HomeAbt from "../../assets/images/Home About.png";
import "../Styles/HomeAbout.css";

const HomeAbout = () => {
const [content, setContent] = useState([]);

useEffect(() => {
  const fetchContent = async () => {
    try
    {
      const response = await axios.get("http://localhost:5000/api/home/home-content");
      const data = response.data.reduce((acc, item) => {
        acc[item.element_id] = item.content;
        return acc;
      },{});
      setContent(data);
    }catch(error) {
      console.error("Error fetching content", error);
    }
  }
  fetchContent();
}, [])

    return (
        <div id="home-about">
            {/* Home page - About us Start */}
            <div id="about-container" className="w-full flex justify-evenly items-center p-4">
                {/* About Content start */}
                <div id="about-content" className="w-1/2 abt-width">
                    <h1 id="about-title" className="mb-[30px] text-[30px] text-gold font-semibold">
                        {content["about-title"]}
                    </h1>
                    <p id="about-summary-1" className="mb-[30px]">
                        {content["about-summary-1"]}
                    </p>
                    <p id="about-summary-2" className="mb-[30px]">
                        {content["about-summary-2"]}
                    </p>
                    <div id="about-icons-section" className="flex justify-center items-center">
                        <div id="icon-container" className="flex flex-col justify-evenly items-center h-full space-y-20">
                            <img
                                id="icon-hairgrowth"
                                src={Hairgrowth}
                               alt="hair growth icon"
                                className="w-[350px] icon-image"
                            />
                            <img
                                id="icon-skinrejuve"
                                src={SkinRejuve}
                                alt="skin rejuvenation icon"
                                className="w-[350px] icon-image"
                            />
                            <img
                                id="icon-acnescar"
                                src={AcneScar}
                                alt="acne scar icon"
                                className="w-[350px] icon-image"
                            />
                        </div>
                        <div id="icon-text-container" className="pl-8 abt-padding">
                            <div >
                                <h4 id="hair-restoration-section" className="font-medium text-[18px] mb-[10px]">
                                {content["hair-restoration-section"]}
                                </h4>
                                <p id="hair-restoration-paragraph" className="mb-[20px]">
                                {content["hair-restoration-paragraph"]}
                                </p>
                            </div>

                            <div >
                                <h4 id="skin-rejuvenation-section" className="font-medium text-[18px] mb-[10px]">
                                {content["skin-rejuvenation-section"]}
                                </h4>
                                <p id="skin-rejuvenation-paragraph" className="mb-[20px]">
                                {content["skin-rejuvenation-paragraph"]}
                                </p>
                            </div>

                            <div >
                                <h4 id="acne-scar-section" className="font-medium text-[18px] mb-[10px]">
                                    {content["acne-scar-section"]}
                                </h4>
                                <p id="acne-scar-paragraph" className="mb-[20px]">
                                {content["acne-scar-paragraph"]}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="know-more-button-container" className="w-[250px] mt-[20px] mb-[15px] abt-button">
                        <Link to="/about#about">
                            <p
                                id="know-more-button"
                                className="text-[16px] text-center p-2 px-4 font-medium text-white rounded-3xl bg-gold cursor:pointer"
                            >
                                {content["know-more-button"]}
                            </p>
                        </Link>
                    </div>
                </div>
                {/* About Content end */}
                {/* About image start */}
                <div id="about-image-container" className="py-8">
                    <img
                        id="about-main-image"
                        src={HomeAbt}
                        className="h-[800px] main-image"
                        alt="Home about"
                    />
                </div>
                {/* About image end */}
            </div>
            {/* Home page about us end */}
        </div>
    );
};

export default HomeAbout;
