import React, {useEffect, useState} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../Styles/TestimonialCarousal.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import required Swiper modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import axios from "axios"
import testib1 from "../../assets/images/testib1.png";
import testia1 from "../../assets/images/testia1.png";
import testib2 from "../../assets/images/testib2.png";
import testia2 from "../../assets/images/testia2.png";
import testib3 from "../../assets/images/testib3.png";
import testia3 from "../../assets/images/testia3.png";
import testib4 from "../../assets/images/testib4.png";
import testia4 from "../../assets/images/testia4.png";
import testib5 from "../../assets/images/testib5.png";
import testia5 from "../../assets/images/testia5.png";
import testib6 from "../../assets/images/testib6.png";
import testia6 from "../../assets/images/testia6.png";
// import testib7 from "../assets/images/testib7.png";
// import testia7 from "../assets/images/testia7.png";

const TestimonialCarousel = () => {
  const testimonials = [
    {
      before: testib1,
      after: testia1,
      review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      rating: 5,
      name: "Mr. Vignesh Kumar",
    },
    {
      before: testib2,
      after: testia2,
      review: "Ut eget elementum quam. Nullam nec arcu sit amet.",
      rating: 4,
      name: "Mr. Ramesh Kumar",
    },
    {
      before: testib3,
      after: testia3,
      review: "Ut eget elementum quam. Nullam nec arcu sit amet.",
      rating: 4,
      name: "Mr. Ramesh Kumar",
    },
    {
      before: testib4,
      after: testia4,
      review: "Ut eget elementum quam. Nullam nec arcu sit amet.",
      rating: 4,
      name: "Mr. Ramesh Kumar",
    },
    // {
    //   before: testib7,
    //   after: testia7,
    //   review: "Ut eget elementum quam. Nullam nec arcu sit amet.",
    //   rating: 4,
    //   name: "Mr. Ramesh Kumar",
    // },
    {
      before: testib5,
      after: testia5,
      review: "Ut eget elementum quam. Nullam nec arcu sit amet.",
      rating: 4,
      name: "Mr. Ramesh Kumar",
    },
    {
      before: testib6,
      after: testia6,
      review: "Ut eget elementum quam. Nullam nec arcu sit amet.",
      rating: 4,
      name: "Mr. Ramesh Kumar",
    },
  ];

  const [content, setContent] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      try
      {
        const response = await axios.get("http://localhost:5000/api/home/home-content");
        const data = response.data.reduce((acc, item) => {
          acc[item.element_id] = item.content;
          return acc;
        },{});
        setContent(data);
      }catch(error) {
        console.error("Error fetching content", error);
      }
    }
    fetchContent();
  }, [])

  return (
    <div className="testimonial-carousel">
      <h1 className="text-3xl font-semibold text-center mt-[30px] mb-4">
        {content["testimonials"]}
      </h1>
      <p className="text-center font-medium mb-6">
       {content["hearitdirectly"]}
      </p>

      <Swiper
        slidesPerView={3}
        centeredSlides={true}
        spaceBetween={10}
        loop={true}
        pagination={{ clickable: true }}
        navigation={true}
        autoplay={{
          delay: 3000, // Delay between slides in milliseconds
          disableOnInteraction: false, // Don't stop autoplay when user interacts with carousel
        }}
        modules={[Pagination, Navigation, Autoplay]} // Add Autoplay module
        className="mySwiper"
        breakpoints={{
          300: { slidesPerView: 1 },
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
          1280: { slidesPerView: 3}
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className=" mb-20 ml-12 mr-12 bg-yellow-500  p-6 shadow-3xl text-center">
              <div className="flex justify-evenly items-center mb-4 space-x-6">
                <div>
                  <p className="font-semibold mb-[10px]">{content[`before${index + 1}`]}</p>
                  <img
                    src={testimonial.before}
                    alt="Before"
                    className="object-cover rounded-lg w-full h-full"
                  />
                </div>
                <div>
                  <p className="font-semibold mb-[10px]">{content[`after${index + 1}`]}</p>
                  <img
                    src={testimonial.after}
                    alt="After"
                    className="object-cover rounded-lg w-full h-full"
                  />
                </div>
              </div>
              <div className="flex justify-center mb-2">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <span key={i} className="text-yellow-300 text-xl">
                    ⭐
                  </span>
                ))}
              </div>
              <p className="text-white mb-4">{content[`review${index + 1}`]}</p>
              <p className="font-bold text-white">{content[`name${index + 1}`]}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TestimonialCarousel;
