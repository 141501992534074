import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/HomeService.css";
import axios from "axios";
import Hair1 from "../../assets/images/scalp migration icon.png";
import Hair2 from "../../assets/images/hair2.png";
import Hair3 from "../../assets/images/hair3.png";
import Hair4 from "../../assets/images/hair4.png";
import Hair5 from "../../assets/images/hair5.png";
import Hair6 from "../../assets/images/hair6.png";
import Hairr1 from "../../assets/images/hairr1.png";
import Hairr2 from "../../assets/images/hairr2.png";
import Hairr3 from "../../assets/images/hairr3.png";
import Hairr4 from "../../assets/images/hairr4.png";
import Hairr5 from "../../assets/images/hairr5.png";
import Hairr6 from "../../assets/images/hairr6.png";
import Skin1 from "../../assets/images/skin1.png";
import Skin2 from "../../assets/images/skin2.png";
import Skin3 from "../../assets/images/skin3.png";
import Skin4 from "../../assets/images/skin4.png";
import Skin5 from "../../assets/images/skin5.png";
import Skin6 from "../../assets/images/skin6.png";
import Skin7 from "../../assets/images/skin7.png";
import Skin8 from "../../assets/images/skin8.png";
import Skin9 from "../../assets/images/skin9.png";
import Skin10 from "../../assets/images/skin6 (2).png";
import Skin11 from "../../assets/images/skin11.png";
import Skin12 from "../../assets/images/35.png";
import Design from "../../assets/images/Design.png";

const HomeService = () => {
  const [activeTab, setActiveTab] = useState("Hair Care");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [content, setContent] = useState([]);

useEffect(() => {
  const fetchContent = async () => {
    try
    {
      const response = await axios.get("http://localhost:5000/api/home/home-content");
      const data = response.data.reduce((acc, item) => {
        acc[item.element_id] = item.content;
        return acc;
      },{});
      setContent(data);
    }catch(error) {
      console.error("Error fetching content", error);
    }
  }
  fetchContent();
}, [])

  return (
    <div className="overflow-hidden">
      <div className=" service-content bg-gold px-16 py-6 overflow-hidden">
        <div className="flex justify-between items-center ">
          <h1 id="homeservices" className=" mb-[30px] text-[30px] font-semibold">OUR SERVICES</h1>
          <Link to="/treatments/hair-care#haircare">
            <p id="view-all-services" className=" nrm-btn bg-black text-white px-4 py-2 rounded-3xl font-medium mt-[-30px] cursor:pointer">
              {content["view-all-services"]}
            </p>
          </Link>
        </div>
        <p id="view-all-services-para" className="mb-10">
        {content["view-all-services-para"]}
        </p>
        <div className="text-center">
          <h1 id="treatments-prov" className="font-semibold text-[24px] mb-8">
          {content["treatments-prov"]}
          </h1>
          <div className="flex justify-evenly items-center text-[20px] font-medium">
            <div
              className="flex justify-between space-x-2 items-center cursor-pointer"
              onClick={() => handleTabClick("Hair Care")}
            >
              <p id="hair-care-home-bg"
                className={`rounded-full border-[4px]  text-white p-[10px] ${
                  activeTab === "Hair Care" ? "bg-black text-white" : "bg-white"
                }`}
              ></p>
              <p id="hair-care-home">{content["hair-care-home"]}</p>
            </div>
            <div
              className="flex justify-between space-x-2 items-center cursor-pointer"
              onClick={() => handleTabClick("SKIN CARE")}
            >
              <p id="skin-care-home-bg"
                className={`rounded-full border-[4px] text-white p-[10px] ${
                  activeTab === "SKIN CARE" ? "bg-black text-white" : "bg-white"
                }`}
              ></p>
              <p id="skin-care-home">{content["skin-care-home"]}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mt-[-300px] ml-[-300px] mr-[-200px]">
          <img id="design1" src={Design} alt="design"></img>
          <img id="design2" src={Design} alt="design"></img>
        </div>
      </div>

      {activeTab === "Hair Care" && (
        <div className="service-container bg-white px-16 py-6 flex flex-col space-y-10">
          <div className="flex justify-evenly items-center  space-x-4 ">
            <Link to="/treatments/hair-care#hairloss">
              <div className=" serv-cont1 overflow-hidden relative rounded-tl-3xl shadow-3xl border-4 border-gold rounded-br-3xl w-[250px] h-[280px] bg-grey">
                <img id="hairr1"
                  src={Hairr1}
                  alt=""
                  className="rounded-tl-3xl rounded-br-3xl h-full zoom-effect"
                ></img>
                <div className="flex rounded-br-3xl justify-between items-center px-4 bg-black space-x-5 text-white opacity-90 mt-[-100px] h-[100px] ">
                  <img id="hair6" src={Hair6} alt="" className="w-[90px] p-2"></img>
                  <div>
                    <p id="hairlosstreat" className="text-[16px] font-medium">
                    {content["hairlosstreat"]}
                    </p>
                    <p id="knowmorehome" className="text-gold text-[13px]">{content["knowmorehome"]}</p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/treatments/hair-care#hairtransplant">
              <div className="serv-cont2 overflow-hidden relative rounded-tl-3xl shadow-3xl rounded-br-3xl w-[250px] border-4 border-gold h-[280px] bg-grey">
                <img id="hairr2"
                  src={Hairr2}
                  alt=""
                  className="rounded-tl-3xl rounded-br-3xl h-full zoom-effect"
                ></img>
                <div className="flex  rounded-br-3xl justify-between items-center px-4 bg-black space-x-5 text-white opacity-90 mt-[-100px] h-[100px] ">
                  <img id="hair5" src={Hair5} alt="" className="w-[90px] p-2"></img>
                  <div>
                    <p id="prptreat" className="text-[16px] font-medium">{content["prptreat"]}</p>
                    <p id="knowmorehm" className="text-gold text-[13px]">{content["knowmorehm"]}</p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/treatments/hair-care#prp">
              <div className="serv-cont3 overflow-hidden relative rounded-tl-3xl shadow-3xl rounded-br-3xl w-[250px] h-[280px] bg-grey border-4 border-gold">
                <img
                id="hairr3"
                  src={Hairr3}
                  alt=""
                  className="rounded-tl-3xl rounded-br-3xl h-full zoom-effect"
                ></img>
                <div  className="flex rounded-br-3xl justify-between items-center px-4 bg-black space-x-5 text-white opacity-90 mt-[-100px] h-[100px] ">
                  <img id="hair2" src={Hair2} alt="" className="w-[90px] p-2"></img>
                  <div>
                    <p id="hairtrans" className="text-[16px] font-medium">{content["hairtrans"]}</p>
                    <p id="hairtransknw" className="text-gold text-[13px]">{content["hairtransknw"]}</p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/treatments/hair-care#hairdensity">
              <div className="serv-cont4 overflow-hidden relative rounded-tl-3xl shadow-3xl rounded-br-3xl w-[250px] h-[280px] bg-grey border-4 border-gold">
                <img
                id="hairr4"
                  src={Hairr4}
                  alt=""
                  className="rounded-tl-3xl rounded-br-3xl h-full zoom-effect"
                ></img>
                <div className="flex  rounded-br-3xl justify-between items-center px-4 bg-black space-x-5 text-white opacity-90 mt-[-100px] h-[100px] ">
                  <img id="hair4" src={Hair4} alt="" className="w-[90px] p-2"></img>
                  <div>
                    <p id="hairdense" className="text-[16px] font-medium">{content["hairdense"]}</p>
                    <p id="hairdenseknw" className="text-gold text-[13px]">{content["hairdenseknw"]}</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="flex justify-center items-center space-x-10">
            <Link to="/treatments/hair-care#beard&mustache">
              <div className="serv-cont5 overflow-hidden relative rounded-tl-3xl shadow-3xl rounded-br-3xl w-[250px] h-[280px] bg-grey border-4 border-gold">
                <img
                id="hairr5"
                  src={Hairr5}
                  alt=""
                  className="rounded-tl-3xl rounded-br-3xl h-full zoom-effect"
                ></img>
                <div className="flex rounded-br-3xl justify-between items-center px-4 bg-black space-x-5 text-white opacity-90 mt-[-100px] h-[100px] ">
                  <img id="hair3" src={Hair3} alt="" className="w-[90px] p-2"></img>
                  <div>
                    <p id="breadmust" className="text-[16px] font-medium">
                    {content["breadmust"]}
                    </p>
                    <p id="breadmustknw" className="text-gold text-[13px]">{content["breadmustknw"]}</p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/treatments/hair-care#scalpmigration">
              <div className="serv-cont6 overflow-hidden relative rounded-tl-3xl shadow-3xl rounded-br-3xl w-[250px] h-[280px] bg-grey border-4 border-gold">
                <img
                id="hairr6"
                  src={Hairr6}
                  alt=""
                  className="rounded-tl-3xl rounded-br-3xl h-full zoom-effect"
                ></img>
                <div className="flex rounded-br-3xl justify-between items-center px-4 bg-black space-x-5 text-white opacity-90 mt-[-100px] h-[100px] ">
                  <img id="hair1" src={Hair1} alt="" className="w-[90px] p-2"></img>
                  <div>
                    <p id="scalpmig" className="text-[16px] font-medium">{content["scalpmig"]}</p>
                    <p id="scalmigknw" className="text-gold text-[13px]">{content["scalmigknw"]}</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      )}
      {activeTab === "SKIN CARE" && (
        <div className="service-container bg-white px-16 py-6 flex flex-col space-y-10 ">
          <div className="flex justify-evenly items-center  space-x-4 ">
            <Link to="treatments/skin-care#skin-lightening">
              <div className=" serv-cont7 overflow-hidden relative rounded-tl-3xl shadow-3xl border-4 border-gold rounded-br-3xl w-[250px] h-[280px] bg-grey">
                <img
                id="skin1"
                  src={Skin1}
                  alt=""
                  className="rounded-tl-3xl rounded-br-3xl h-full zoom-effect"
                ></img>
                <div className="flex rounded-br-3xl justify-between items-center px-4 bg-black space-x-5 text-white opacity-90 mt-[-100px] h-[100px] ">
                  <img id="skin11" src={Skin11} alt="" className="w-[90px] p-2"></img>
                  <div>
                    <p id="skinlight" className="text-[16px] font-medium">{content["skinlight"]}</p>
                    <p id="skinlightknw" className="text-gold text-[13px]">{content["skinlightknw"]}</p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="treatments/skin-care#botox">
              <div className="serv-cont8 overflow-hidden relative rounded-tl-3xl shadow-3xl border-4 border-gold rounded-br-3xl w-[250px] h-[280px] bg-grey">
                <img
                id="skin10"
                  src={Skin10}
                  alt=""
                  className="rounded-tl-3xl rounded-br-3xl h-full zoom-effect"
                ></img>
                <div className="flex rounded-br-3xl justify-between items-center px-4 bg-black space-x-5 text-white opacity-90 mt-[-100px] h-[100px] ">
                  <img id="skin12" src={Skin12} alt="" className="w-[90px] p-2"></img>
                  <div>
                    <p id="botox" className="text-[16px] font-medium">{content["botox"]}</p>
                    <p id="botoxknw" className="text-gold text-[13px]">{content["botoxknw"]}</p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="treatments/skin-care#tattooremoval">
              <div className="serv-cont9 overflow-hidden relative rounded-tl-3xl shadow-3xl border-4 border-gold rounded-br-3xl w-[250px] h-[280px] bg-grey">
                <img
                id="skin3"
                  src={Skin3}
                  alt=""
                  className="rounded-tl-3xl rounded-br-3xl h-full zoom-effect"
                ></img>
                <div className="flex rounded-br-3xl justify-between items-center px-4 bg-black space-x-5 text-white opacity-90 mt-[-100px] h-[100px] ">
                  <img id="skin6" src={Skin6} alt="" className="w-[90px] p-2"></img>
                  <div>
                    <p id="tattoo" className="text-[16px] font-medium">{content["tattoo"]}</p>
                    <p id="tattooknw" className="text-gold text-[13px]">{content["tattooknw"]}</p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="treatments/skin-care#laser">
              <div className="serv-cont10 overflow-hidden relative rounded-tl-3xl shadow-3xl border-4 border-gold rounded-br-3xl w-[250px] h-[280px] bg-grey">
                <img
                id="skin4"
                  src={Skin4}
                  alt=""
                  className="rounded-tl-3xl rounded-br-3xl h-full zoom-effect"
                ></img>
                <div className="flex rounded-br-3xl justify-between items-center px-4 bg-black space-x-5 text-white opacity-90 mt-[-100px] h-[100px] ">
                  <img id="skin9" src={Skin9} alt="" className="w-[90px] p-2"></img>
                  <div>
                    <p id="laserhairre" className="text-[16px] font-medium">
                    {content["laserhairre"]}
                    </p>
                    <p id="laserhairknw" className="text-gold text-[13px]">{content["laserhairknw"]}</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="flex justify-center items-center space-x-10">
            <Link to="treatments/skin-care#hydrafacial">
              <div className="serv-cont11 overflow-hidden relative rounded-tl-3xl shadow-3xl border-4 border-gold rounded-br-3xl w-[250px] h-[280px] bg-grey">
                <img
                id="skin5"
                  src={Skin5}
                  alt=""
                  className="rounded-tl-3xl rounded-br-3xl h-full zoom-effect"
                ></img>
                <div className="flex  rounded-br-3xl justify-between items-center px-4 bg-black space-x-5 text-white opacity-90 mt-[-100px] h-[100px] ">
                  <img id="skin7" src={Skin7} alt="" className="w-[90px] p-2"></img>
                  <div>
                    <p id="hydrafacial" className="text-[16px] font-medium">{content["hydrafacial"]}</p>
                    <p id="hydrafacialknw" className="text-gold text-[13px]">{content["hydrafacialknw"]}</p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="treatments/skin-care#acnescarremoval">
              <div className="serv-cont12 overflow-hidden relative rounded-tl-3xl shadow-3xl border-4 border-gold rounded-br-3xl w-[250px] h-[280px] bg-grey">
                <img
                id="skin2"
                  src={Skin2}
                  alt=""
                  className="rounded-tl-3xl rounded-br-3xl h-full zoom-effect"
                ></img>
                <div className="flex rounded-br-3xl justify-between items-center px-4 bg-black space-x-5 text-white opacity-90 mt-[-100px] h-[100px] ">
                  <img id="skin8" src={Skin8} alt="" className="w-[90px] p-2"></img>
                  <div>
                    <p id="acnescar" className="text-[16px] font-medium">
                    {content["acnescar"]}
                    </p>
                    <p id="acnescarknw" className="text-gold text-[13px]">{content["acnescarknw"]}</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      )}
      <p id="viewall" className=" res-btn bg-black text-white px-4 py-2 rounded-3xl font-medium text-center mt-[-30px]">
      {content["viewall"]}
      </p>
      <div className="flex justify-between items-center mt-[-300px]  ">
        <img id="design" src={Design} alt="design"></img>
        <img id="design" src={Design} alt="design"></img>
      </div>
    </div>
  );
};

export default HomeService;
