import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import Design from "../assets/images/Design.png";
import Aboutbanner from "../assets/images/About us.png";
import Hairgrowth from "../assets/images/Hair growth.png";
import SkinRejuve from "../assets/images/Skin rejuvenation.png";
import AcneScar from "../assets/images/Acne treatment.png";
import icon1 from "../assets/images/44 1.png";
import icon2 from "../assets/images/45 1.png";
import icon3 from "../assets/images/46 1.png";
import "../pages/css/About.css";

const About = () => {
  const [content, setContent] = useState({});

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/about/about-us");
        const data = response.data.reduce((acc, item) => {
          acc[item.element_id] = item.content;
          return acc;
        }, {});
        setContent(data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchContent();
  }, []);

  const customerreviews = [
    {
      review:
        "“ The team at F2 Clinic is amazing! They took the time to understand my concerns and provided tailored treatments that truly made a difference. Highly recommend their services! ”",
      name: "Mr. Vignesh Kumar",
    },
    {
      review:
        " “After struggling with hair loss for years, F2 Clinic gave me my confidence back. Their expert approach and caring staff made the experience so positive. Great results! ” ",
      name: "Mr. Santhosh",
    },
    {
      review:
        "“ The team at F2 Clinic is amazing! They took the time to understand my concerns and provided tailored treatments that truly made a difference. Highly recommend their services!“",
      name: "Mr. Vignesh Kumar",
    },
    {
      review:
        " “I’ve tried various skin treatments, but the results here are incredible. My skin has never looked better. The staff is knowledgeable, and they genuinely care about their clients.”   ",
      name: "Mr. Ramya",
    },
  ];
  return (
    <div className="overflow-hidden" id="about">
      <div className="relative w-full">
        <div className="absolute inset-0 bg-black opacity-40"></div>{" "}
        {/* Background overlay */}
        <img
          id="about-banner"
          src={Aboutbanner}
          alt="banner"
          className="w-full h-[500px] object-cover"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center px-4 md:px-8 lg:px-0">
          <h1
            id="about-heading"
            className=" w-[100%] text-gold font-bold mb-[20px] leading-tight responsive-title"
          >
            {content["about-heading"]}
          </h1>
          <h4
            id="about-subtitle"
            className="w-[100%] text-white mb-[30px] leading-normal responsive-subtitle"
          >
           {content["about-subtitle"]}
          </h4>
        </div>
      </div>

      <div className="p-6 md:p-10 lg:p-12">
        <div className="flex flex-col lg:flex-row justify-between items-center space-y-6 lg:space-y-0 lg:space-x-8">
          {/* Text Section */}
          <div id="journey-section" className="lg:w-2/3">
            <h1
              id="journey-heading"
              className="mb-4 font-semibold text-gold text-xl lg:text-2xl"
            >
              {content["journey-heading"]}
            </h1>
            <p id="journey-paragraph-1" className="mb-4 text-sm md:text-base">
             {content["journey-paragraph-1"]}
            </p>
            <p id="journey-paragraph-2" className="mb-4 text-sm md:text-base">
            {content["journey-paragraph-2"]}
            </p>
            <p id="journey-paragraph-3" className="mb-4 text-sm md:text-base">
            {content["journey-paragraph-3"]}
            </p>
            <p id="journey-paragraph-4" className="text-sm md:text-base">
            {content["journey-paragraph-4"]}
            </p>
          </div>

          {/* Video Section */}
          <div
            id="video-section"
            className="lg:w-1/3 flex justify-center lg:justify-end"
          >
            <iframe
              id="about-video"
              className="w-full h-48 md:h-64 lg:h-80"
              src="https://www.youtube.com/embed/qPKvaIycoZE?si=_Wj5Zj3RQguW4VnU"
              title="YouTube video player"
              alt="About F2 Clinic"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        {/* Treatments Section */}
        <div
          id="treatments-section"
          className="flex flex-col lg:flex-row justify-evenly items-start mt-8 space-y-6 lg:space-y-0 lg:space-x-8"
        >
          {/* Icons Section (Hidden on screens below 1024px) */}
          <div
            id="icons-section"
            className="hidden lg:flex flex-row lg:flex-col justify-center space-x-4 lg:space-x-0 lg:space-y-12 lg:mr-8"
          >
            <img
              id="hair-growth-icon"
              src={Hairgrowth}
              alt="Hair Growth"
              className="h-24 w-24 md:h-28 md:w-28 lg:h-20 lg:w-28"
            />
            <img
              id="skin-rejuvenation-icon"
              src={SkinRejuve}
              alt="Skin Rejuvenation"
              className="h-24 w-24 md:h-28 md:w-28 lg:h-20 lg:w-28"
            />
            <img
              id="acne-scar-icon"
              src={AcneScar}
              alt="Acne Scar Treatment"
              className="h-24 w-24 md:h-28 md:w-28 lg:h-20 lg:w-36"
            />
          </div>

          {/* Text Descriptions */}
          <div id="treatment-descriptions">
            <h5
              id="hair-restoration-heading"
              className="font-medium text-lg lg:text-xl mb-4"
            >
              {content["hair-restoration-heading"]}
            </h5>
            <p
              id="hair-restoration-paragraph"
              className="mb-4 text-sm md:text-base"
            >
              {content["hair-restoration-paragraph"]}
            </p>
            <h5
              id="skin-rejuvenation-heading"
              className="font-medium text-lg lg:text-xl mb-4"
            >
              {content["skin-rejuvenation-heading"]}
            </h5>
            <p
              id="skin-rejuvenation-paragraph"
              className="mb-4 text-sm md:text-base"
            >
             {content["skin-rejuvenation-paragraph"]}
            </p>
            <h5
              id="acne-treatment-heading"
              className="font-medium text-lg lg:text-xl mb-4"
            >
              {content["acne-treatment-heading"]}
            </h5>
            <p id="acne-treatment-paragraph" className="text-sm md:text-base">
            {content["acne-treatment-paragraph"]}
            </p>
          </div>
        </div>
      </div>

      <div
        id="statistics-section"
        className="flex flex-col md:flex-row justify-evenly items-center w-full py-8 bg-gold text-center font-medium space-y-6 md:space-y-0"
      >
        <div id="statistic-1" className="flex flex-col items-center space-y-2">
          <img
            id="icon-1"
            src={icon1}
            alt="Icon 1"
            className="w-16 h-16 md:w-20 md:h-20"
          />
          <h1
            id="statistic-1-value"
            className="text-2xl md:text-3xl font-semibold"
          >
            {content["statistic-1-value"]}
          </h1>
          <p id="statistic-1-label" className="text-base md:text-lg">
          {content["statistic-1-label"]}
          </p>
        </div>

        <div className="flex flex-col items-center space-y-2">
          <img src={icon2} alt="Icon 2" className="w-16 h-16 md:w-20 md:h-20" id="icon-2"/>
          <h1 className="text-2xl md:text-3xl font-semibold" id="statistic-2-value">{content["statistic-2-value"]}</h1>
          <p className="text-base md:text-lg" id="statistic-2-label">{content["statistic-2-label"]}</p>
        </div>

        <div className="flex flex-col items-center space-y-2">
          <img src={icon3} alt="Icon 3" className="w-16 h-16 md:w-20 md:h-20" id="icon-3"/>
          <h1 className="text-2xl md:text-3xl font-semibold" id="statistic-3-value">{content["statistic-3-value"]}</h1>
          <p className="text-base md:text-lg" id="statistic-3-label">{content["statistic-3-label"]}</p>
        </div>
      </div>

      <div className="h-[420px]">
        <h1
          id="values-heading"
          className="text-[30px] font-semibold text-center mt-[30px]"
        >
          {content["values-heading"]}
        </h1>
        <h2
          id="guaranteed-results-heading"
          className="text-[20px] font-medium pt-4 text-center"
        >
          {content["guaranteed-results-heading"]}
        </h2>
        <h4 id="values-description-heading" className="pt-4 text-center">
          {content["values-description-heading"]}
        </h4>
        <p id="values-paragraph-1" className="pt-4 pr-10 pl-10 text-center">
        {content["values-paragraph-1"]}
        </p>
        <p id="values-paragraph-2" className="pt-4 text-center">
        {content["values-paragraph-2"]}
        </p>
        <div className="pt-4 flex justify-center items-center space-x-32">
          <ul id="treatments-list-1" className="list-disc text-left space-y-4">
            <li id="hair-li">{content["hair-li"]}</li>
            <li id="skin-li">{content["skin-li"]}</li>
            <li id="prp-li">{content["prp-li"]}</li>
            <li id="scalp-li">{content["scalp-li"]}</li>
          </ul>
          <ul id="treatments-list-2" className="list-disc text-left space-y-4">
            <li id="pigment-li">{content["pigment-li"]}</li>
            <li id="hairre-li">{content["hairre-li"]}</li>
            <li id="tattoo-li">{content["tattoo-li"]}</li>
            <li id="anti-li">{content["anti-li"]}</li>
          </ul>
        </div>
        <div className="flex justify-between items-center mt-[-160px] mr-[-200px] ml-[-200px]">
          <img id="design-img-1" src={Design} alt="design" />
          <img
            id="design-img-2"
            src={Design}
            alt="design1"
            className="scale-x-[-1] mt-[-120px]"
          />
        </div>
      </div>

      <div className=" bg-gold p-10 text-center">
        <h1 id="awards-heading" className="mb-[15px]">
        {content["awards-heading"]}
        </h1>
        <p id="awards-paragraph">
        {content["awards-paragraph"]}
        </p>
        <Swiper
          slidesPerView={3}
          centeredSlides={true}
          spaceBetween={10}
          loop={true}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 3000, // Delay between slides in milliseconds
            disableOnInteraction: false, // Don't stop autoplay when user interacts with carousel
          }}
          modules={[Pagination, Autoplay]}
          className="mySwipe"
          breakpoints={{
            300: { slidesPerView: 1 },
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
            1280: { slidesPerView: 3 },
          }}
        >
          {customerreviews.map((customerreview, index) => (
            <SwiperSlide key={index}>
              <div className="bg-brown p-8 text-white my-[30px] mb-[80px] italic ">
                <p id={`customer-review-${index + 1}`}>
                {content[`customer-review-${index + 1}`]}
                </p>
                <p id={`customer-review-name-${index + 1}`}>
                  {content[`customer-review-name-${index + 1}`]}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default About;
