import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "../Styles/Navbar.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHairCareMenuOpen, setIsHairCareMenuOpen] = useState(false);
  const [isSkinCareMenuOpen, setIsSkinCareMenuOpen] = useState(false);
  const location = useLocation();

  const hairCareMenuRef = useRef(null);
  const skinCareMenuRef = useRef(null);
  const navbarRef = useRef(null);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  
  const toggleHairCareMenu = () => {
    setIsHairCareMenuOpen(!isHairCareMenuOpen);
  };

  const toggleSkinCareMenu = () => {
    setIsSkinCareMenuOpen(!isSkinCareMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      hairCareMenuRef.current && !hairCareMenuRef.current.contains(event.target) &&
      skinCareMenuRef.current && !skinCareMenuRef.current.contains(event.target) &&
      navbarRef.current && !navbarRef.current.contains(event.target)
    ) {
      setIsHairCareMenuOpen(false);
      setIsSkinCareMenuOpen(false);
      setIsMenuOpen(false);  // Close the mobile menu if open
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="w-full h-16 flex justify-evenly items-center py-2 px-0 bg-white shadow-md">
      <Link to="/" className="flex space-x-2 items-center">
        <img src={logo} alt="Logo" className="w-12 h-12" />
        <div className="text-ms font-bold text-gray-800 pt-1">
          <span className="block">SKIN &</span>
          <span className="block">HAIR CLINIC</span>
        </div>
      </Link>

      <ul className="w-[40%] flex font-normal text-sm justify-around items-center list-none nav-hide">
        <li>
          <Link to="/" className="hover:text-gold">
            HOME
          </Link>
        </li>
        <li>
          <Link to="/about" className="hover:text-gold">
            ABOUT
          </Link>
        </li>

        {/* Treatments with Hover Dropdown */}
        <li className="relative group w-32">
          <span className="hover:text-gold flex space-x-4 items-center cursor-pointer" >
            TREATMENTS
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4 mt-0.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>

          {/* Hair Care Menu */}
          
          <ul  className="absolute top-full left-0 mt-2 bg-white shadow-lg rounded-md p-2 min-w-[150px] z-10 hidden group-hover:block">
            <Link to="/treatments/hair-care">
              <li className="pb-4 pt-2 pl-2 hair-care-group relative">
                <span className="hover:text-gold flex space-x-6 items-center cursor-pointer " onClick={toggleHairCareMenu}>
                  HAIR CARE
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 ml-6 mt-0.5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </span>
                {isHairCareMenuOpen && (
                <ul ref={hairCareMenuRef} className="absolute top-[100%] mt-2 bg-white shadow-lg rounded-md p-2 min-w-[150px] z-10 hidden hair-care-menu">
                  <li className="pb-4 pt-2 pl-2">
                    <Link
                      to="/treatments/hair-care#hairloss"
                      className="hover:text-gold w-52 flex space-evenly items-center"
                    >
                      HAIR LOSS
                    </Link>
                  </li>
                  <li className="pb-4 pt-2 pl-2">
                    <Link
                      to="/treatments/hair-care#hairtransplant"
                      className="hover:text-gold w-52 flex space-evenly items-center"
                    >
                      HAIR TRANSPLANT
                    </Link>
                  </li>

                  <li className="pb-4 pt-2 pl-2">
                    <Link
                      to="/treatments/hair-care#prp"
                      className="hover:text-gold w-52 flex space-evenly items-center"
                    >
                      <span>PRP TREATMENT</span>
                    </Link>
                  </li>
                  <li className="pb-4 pt-2 pl-2">
                    <Link
                      to="/treatments/hair-care#hairdensity"
                      className="hover:text-gold w-52 flex space-evenly items-center"
                    >
                      <span>HAIR DENSITY</span>
                    </Link>
                  </li>
                  <li className="pb-4 pt-2 pl-2">
                    <Link
                      to="/treatments/hair-care#beard&mustache"
                      className="hover:text-gold w-52 flex space-evenly items-center"
                    >
                      <span>BEARD AND MOUSTACHE TRANSPLANT</span>
                    </Link>
                  </li>
                  <li className="pb-4 pt-2 pl-2">
                    <Link
                      to="/treatments/hair-care#scalpmigration"
                      className="hover:text-gold w-52 flex space-evenly items-center"
                    >
                      <span>SCALP MIGRATION</span>
                    </Link>
                  </li>
                </ul>
                )}
              </li>
            </Link>

         
            {/* Skin Care Menu */}
            <Link to="/treatments/skin-care">
            <li className="pb-4 pt-2 pl-2 skin-care-group relative">
              <span className="hover:text-gold flex space-x-6 items-center cursor-pointer" onClick={toggleSkinCareMenu}>
                SKIN CARE
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4 ml-6 mt-0.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span>
              {isSkinCareMenuOpen && (
              <ul ref={skinCareMenuRef} className="absolute top-[-6px] left-36 mt-2 bg-white shadow-lg rounded-md p-2 min-w-[150px] z-10 hidden skin-care-menu">
                <li className="pb-4 pt-2 pl-2">
                  <Link
                    to="/treatments/skin-care#skin-lightening"
                    className="hover:text-gold hover:text-gold w-52 flex space-evenly items-center"
                  >
                    SKIN LIGHTENING
                  </Link>
                </li>
                <li className="pb-4 pt-2 pl-2">
                  <Link
                    to="/treatments/skin-care#botox"
                    className="hover:text-gold hover:text-gold w-52 flex space-evenly items-center"
                  >
                    BOTOX AND FILLERS
                  </Link>
                </li>

                <li className="pb-4 pt-2 pl-2">
                  <Link
                    to="/treatments/skin-care#laser"
                    className="hover:text-gold w-52 flex space-evenly items-center"
                  >
                    <span>LASER HAIR REMOVAL</span>
                  </Link>
                </li>
                <li className="pb-4 pt-2 pl-2">
                  <Link
                    to="/treatments/skin-care#acnescarremoval"
                    className="hover:text-gold w-52 flex space-evenly items-center"
                  >
                    <span>ACNE SCAR REMOVAL</span>
                  </Link>
                </li>
                <li className="pb-4 pt-2 pl-2">
                  <Link
                    to="/treatments/skin-care#hydrafacial"
                    className="hover:text-gold w-52 flex space-evenly items-center"
                  >
                    <span>HYDRA FACIAL</span>
                  </Link>
                </li>
                <li className="pb-4 pt-2 pl-2">
                  <Link
                    to="/treatments/skin-care#tattooremoval"
                    className="hover:text-gold w-52 flex space-evenly items-center"
                  >
                    <span>TATTOO REMOVAL</span>
                  </Link>
                </li>
                <li className="pb-4 pt-2 pl-2">
                  <Link
                    to="/treatments/skin-care#microneedling"
                    className="hover:text-gold w-52 flex space-evenly items-center"
                  >
                    <span>MICRO NEEDLING</span>
                  </Link>
                </li>
                <li className="pb-4 pt-2 pl-2">
                  <Link
                    to="/treatments/skin-care#tanremoval"
                    className="hover:text-gold w-52 flex space-evenly items-center"
                  >
                    <span>TAN REMOVAL</span>
                  </Link>
                </li>
                <li className="pb-4 pt-2 pl-2">
                  <Link
                    to="/treatments/skin-care#chemicalpeel"
                    className="hover:text-gold w-52 flex space-evenly items-center"
                  >
                    <span>CHEMICAL PEEL</span>
                  </Link>
                </li>
                <li className="pb-4 pt-2 pl-2">
                  <Link
                    to="/treatments/skin-care#wart&mole"
                    className="hover:text-gold w-52 flex space-evenly items-center"
                  >
                    <span>WART AND MOLE REMOVAL</span>
                  </Link>
                </li>
                <li className="pb-4 pt-2 pl-2">
                  <Link
                    to="/treatments/skin-care#undereyecircle"
                    className="hover:text-gold w-52 flex space-evenly items-center"
                  >
                    <span>UNDER EYE DARK CIRCLE</span>
                  </Link>
                </li>
                <li className="pb-4 pt-2 pl-2">
                  <Link
                    to="/treatments/skin-care#laserresurfacing"
                    className="hover:text-gold w-52 flex space-evenly items-center"
                  >
                    <span>LASER RESURFACING</span>
                  </Link>
                </li>
                <li className="pb-4 pt-2 pl-2">
                  <Link
                    to="/treatments/skin-care#carbonpeel"
                    className="hover:text-gold w-52 flex space-evenly items-center"
                  >
                    <span>CARBON PEEL</span>
                  </Link>
                </li>
              </ul>
              )}
            </li>
            </Link>
          </ul>
        </li>

        <li>
          <Link to="/contact" className="hover:text-gold">
            CONTACT US
          </Link>
        </li>
      </ul>

      {/* Appointment Button */}
      <Link
        to="/contact#appointment"
        className="text-sm text-center p-2 px-4 font-medium text-white rounded-3xl bg-gold nav-hide"
      >
        BOOK AN APPOINTMENT
      </Link>

      {/* Hamburger Menu for Small Screens */}
      <button
        className="md:hidden flex items-center text-gray-800"
        onClick={toggleMenu}
      >
        <svg
          className="w-8 h-8"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            d="M4 6h16M4 12h16m-7 6h7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      {/* Dropdown Menu for Small Screens */}
      {isMenuOpen && (
        <div className="md:hidden absolute top-16 left-0 w-full bg-white shadow-lg rounded-md py-4 px-4 z-10">
          <ul className="flex flex-col space-y-4">
            <li>
              <Link
                to="/"
                className="text-gray-800 hover:text-gold"
                onClick={() => setIsMenuOpen(false)}
              >
                HOME
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="text-gray-800 hover:text-gold"
                onClick={() => setIsMenuOpen(false)}
              >
                ABOUT
              </Link>
            </li>
            <li>
              <Link
                to="/treatments/hair-care"
                className="text-gray-800 hover:text-gold"
                onClick={() => setIsMenuOpen(false)}
              >
                TREATMENTS
              </Link>
            </li>
            <li>
              <Link
                to="/contact#contact"
                className="text-gray-800 hover:text-gold"
                onClick={() => setIsMenuOpen(false)}
              >
                CONTACT US
              </Link>
            </li>
            <li>
              <Link
                to="/contact#appointment"
                className="text-white bg-gold rounded-lg text-center py-2"
                onClick={() => setIsMenuOpen(false)}
              >
                BOOK AN APPOINTMENT
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navbar;
